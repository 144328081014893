
// substrate and utils
import EventManager                   from '@brainscape/event-manager';
import NumberHelper                   from '_utils/NumberHelper';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import scrollIntoView                 from 'scroll-into-view-if-needed';
import UiHelper                       from '_utils/UiHelper';
import { toClassStr }                 from '_utils/UiHelper';
import { trackEvent }                 from '_services/AnalyticsService';

// models
import category                       from '_models/category';
import categorySubscription           from '_models/categorySubscription';
import userPack                       from '_models/userPack';

// sub-components
import BruceButton                    from '_views/shared/BruceButton';
import DashboardOptionsButton         from '_views/shared/DashboardOptionsButton';
import DynamicTooltipIcon             from '_views/shared/DynamicTooltipIcon';
import PillButton                     from '_views/shared/PillButton';
import SidebarPack                    from '_views/shared/SidebarPack';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import SiteMenu                       from '_views/shared/SiteMenu';
import Stat                           from '_views/shared/Stat';
import Spinner                        from '_views/shared/Spinner';
import UserAvatar                     from '_views/shared/UserAvatar';

import {
  AcademyButton,
  BackButton,
  ClearButton,
  CircledAddButton,
  ContractButton,
  ExtendButton,
  MoneyButton,
  MoreOutlineButton,
  PeopleButton,
  PhoneButton,
  RetractUpButton,
  SearchButton,
}                                     from '_views/shared/IconButton';

const CONTENT_TYPE_OPTIONS = [
  {id: 'md', label: 'Markdown'},
  {id: 'text', label: 'Text'},
  {id: 'html', label: 'HTML'},
];



const PT = {      
  addClasses:                           PropTypes.string,
  currentPack:                          PropTypes.object,
  currentPackId:                        PropTypes.node,
  currentUser:                          PropTypes.object,
  currentUserPacks:                     PropTypes.array,
  isEmptyLibrary:                       PropTypes.bool,
  isLoadingTrendingPacks:               PropTypes.bool,
  isLoadingUserInfo:                    PropTypes.bool,
  isLoadingUserPacks:                   PropTypes.bool,
  isMobileViewportSize:                 PropTypes.bool,
  isRestrictedByPrivacy:                PropTypes.bool,
  isShowingCachedUserPacks:             PropTypes.bool,
  shouldPulseCreatePackButton:          PropTypes.bool,
  shouldPulseFindButton:                PropTypes.bool,
  sidebarMode:                          PropTypes.string,
  trendingPacks:                        PropTypes.array,
  userPacks:                            PropTypes.array,
};


class DashboardSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderCollapsed:        false,
      isUserPacksFilterEnabled: false,
      isSidebarModeMutable:     true,
      isSiteMenuOpen:           false,
      locallyFilteredUserPacks: this.props.userPacks,
    };

    this.ENABLE_FILTER_PACK_COUNT = 6; // Provide User Packs filter if user has this many Classes in their library

    this.userAvatarElem = null;

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.subscribeToEvents();
    this.scrollToCurrentPack();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingUserPacks && !this.props.isLoadingUserPacks) {
      this.scrollToCurrentPack();
    }

    if (prevProps.currentPackId != this.props.currentPackId) {
      this.scrollToCurrentPack();
    }    
  }

  componentWillUnmount() {
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('current-pack:scroll-to-request', this.handleCurrentPackScrollToRequest);
    this.events.addListener('detail-overlay:closed',          this.handleDetailOverlayClosed);
    this.events.addListener('detail-overlay:opened',          this.handleDetailOverlayOpened);
  } 

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isProClass = this.props.currentUser.flags.isPro ? 'is-pro' : '';
    const isMiniClass = (this.props.sidebarMode == 'mini') ? 'is-mini' : '';

    const classes = toClassStr(['sidebar dashboard-sidebar', isProClass, isMiniClass, this.props.addClasses]);

    return (
      <div className={classes}>

        <header className="sidebar-header full-sidebar-header">

          <div className="sidebar-header-top-row full-sidebar-header-top-row">
            {this.renderChangeModeButton()}

            <div className='bruce-and-gear-icons'>
              <BruceButton 
                addClasses="full-sidebar-bruce-button"
                onClick={() => this.handleSiteMenuBruceButtonClick()}
                tooltipContent="Brainscape resources"
                tooltipPosition="right"
              />

              <div className="sidebar-header-options-button full-sidebar-header-options-button">
                {this.renderSidebarOptionsButton()}
              </div>
            </div>

            <div className="sidebar-header-top-row-center full-sidebar-header-top-row-center">
              {this.renderUser()}
            </div>

            <div className="sidebar-header-options-button mini-sidebar-header-options-button">
              {this.renderSidebarOptionsButton()}
            </div>

            <div className="sidebar-header-user-stats">
              {this.renderUserStats()}
            </div>

          </div>

          <div className="sidebar-header-bottom-row full-sidebar-header-bottom-row">
            {this.renderSidebarHeaderBottomRow()}
          </div>

        </header>

        <div className="sidebar-body full-sidebar-body" ref={(elem) => {this.bodyElem = elem}}>
          <div className="body-scrolling-elem" ref={(elem) => {this.bodyScrollingElem = elem}}>
            {this.renderSidebarBody()}
          </div>
        </div>

        <footer className="sidebar-footer full-sidebar-footer">
          {this.renderSidebarFooter()}
        </footer>
      </div>
    );
  }

  renderChangeModeButton() {
    if (this.props.isMobileViewportSize) {
      return null;
    }

    if (!this.state.isSidebarModeMutable) {
      return null;
    }

    if (this.props.sidebarMode == 'mini') {
      return (
        <ExtendButton 
          addClasses="extend-sidebar-button"
          onClick={this.handleExtendSidebarButtonClick}
          tooltipContent="Extend to Full Sidebar"
          tooltipPosition="right"
        />
      );
    }

    return (
      <ContractButton 
        addClasses="contract-sidebar-button"
        onClick={this.handleContractSidebarButtonClick}
        tooltipContent="Contract to Mini Sidebar"
        tooltipPosition="top"
      />
    );
  }

  renderUser() {
    const userProfile = this.props.currentUser.profile;
    const tooltipContent = "Manage Account, Change Profile Picture, etc.";

    if (this.props.isLoadingUserInfo) {
      return (
        <div className="user">
          {this.renderSpinner()}
        </div>
      );
    }

    return (
      <div className="user">
        <UserAvatar
          avatarUrl={userProfile.avatarUrl}
          avatarName={userProfile.fullName}
          linkUrl="/account"
          tooltipContent={tooltipContent}
          tooltipPosition="right"
        />

        <h3 className="user-name" title={userProfile.firstName}>
          Hi, {this.props.currentUser.profile.firstName}!
        </h3>
      </div>
    );
  }

  renderUserStats() {
    if (this.props.isLoadingUserInfo) {
      return null;
    }

    const userStats = this.props.currentUser.stats;
    const daysStreak = NumberHelper.displayNumber(userStats.daysStreak);
    const decksCreated = NumberHelper.displayNumber(userStats.decksCreated);
    const cardsStudied = NumberHelper.displayNumber(userStats.cardsStudied);

    return (
      <div className="user-stats">
        <Stat
          background="dark"
          label={"Days \n streak"}
          orientation="vertical"
          value={daysStreak}
        />

        <Stat
          background="dark"
          label={"Created \n decks"}
          orientation="vertical"
          value={decksCreated}
        />

        <Stat 
          background="dark"
          label={"Cards \n studied"}
          orientation="vertical"
          value={cardsStudied}
        />
      </div>
    );
  }

  renderSidebarOptionsButton() {
    return (
      <DashboardOptionsButton
        currentUser={this.props.currentUser}
        isUserPro={this.props.currentUser.flags.isPro}
        openPosition="bottomLeft"
        shouldButtonTurn={true}
        tooltipContent="Manage account, log out"
        tooltipPosition={'right'}
        userProfilePath={`/profiles/${this.props.currentUser.profileId}`}
      />
    );
  }

  renderSidebarHeaderBottomRow() {
    const tooltipPosition = (this.props.sidebarMode == 'mini') ? 'right' : 'top';

    return (
      <div className="second-row">

        <h4 className="my-classes-heading">My Classes {this.renderUserPackCount()}</h4>

        <div className="action-buttons">
          <SearchButton
            addClasses={this.props.shouldPulseFindButton ? 'pulse' : ''}
            onClick={() => this.handleFindClassButtonClick()}
            tooltipContent="Find Flashcards on Brainscape"
            tooltipPosition={tooltipPosition}
          />

          {this.renderAddNewPackButton()}
        </div>
      </div>
    );
  }

  renderAddNewPackButton() {
    const pulseClass = this.props.shouldPulseCreatePackButton ? 'pulse' : '';
    const classes = toClassStr(['add-new-pack-button', pulseClass]);

    return (
      <CircledAddButton
        addClasses={classes}
        onClick={this.handleCreatePackButtonClick}
        tooltipContent="Add New Class"
        tooltipPosition="top"
      />
    );
  }

  renderUserPackCount() {
    if (this.props.isEmptyLibrary) {
      return null;
    }


    if (!this.props.isShowingCachedUserPacks && this.props.isLoadingUserPacks) {
      // we don't have cached user packs to display and we are still loading the first paginated page
      return (
        <div className='user-pack-count'>
          {this.renderSpinner()}
        </div>
      )
    }

    const userPackCount = (this.props.userPacks?.length) ? this.props.userPacks.length : 0;
    const displayUserPackCount = NumberHelper.displayNumber(userPackCount) || 0;

    return (
      <div className='user-pack-count'>({displayUserPackCount})</div>
    )
  }

  renderSidebarBody() {
    return (
      <div className="sidebar-sections" ref={(elem) => {this.sidebarSections = elem}}>

        <section className="sidebar-section my-classes" ref={(elem) => {this.myClassesSection = elem}}>
          {/* {this.renderUserPacksFilter()} */}
          {this.renderUserPacks()}
          {this.renderAddClassFooter()}
        </section>

        <section className="sidebar-section trending-classes">

          <header className="sidebar-section-header trending-classes-header">
            <h4 className="section-heading trending-classes-heading">Trending Certified Classes</h4>
          </header>

          {this.renderTrendingPacks()}
          {this.renderTrendingPacksFooter()}
        </section>
      </div>
    );
  }

  renderSidebarFooter() {
    if (this.props.sidebarMode == 'mini') {
      return this.renderMiniSidebarFooter();
    }

    return this.renderFullSidebarFooter();
  }

  renderFullSidebarFooter() {
    return (
      <div className="full-sidebar-footer">
        {this.renderAcademyButton()}
        {this.renderFeatureOsButton()} 
        {this.renderUpgradeButton()}
        {this.renderMoneyAndOrGetAppButton()}

        {this.renderSiteMenu()} 
      </div>
    );
  }

  renderMiniSidebarFooter() {
    return (
      <div className="mini-sidebar-footer">

        <CircledAddButton
          addClasses={this.props.shouldPulseCreatePackButton ? 'pulse' : ''}
          onClick={() => this.handleCreatePackButtonClick()}
          tooltipContent="Create your own Flashcards"
          tooltipPosition="right"
        />

        <SearchButton
          addClasses={this.props.shouldPulseFindButton ? 'pulse' : ''}
          onClick={() => this.handleFindClassButtonClick()}
          tooltipContent="Find Flashcards on Brainscape"
          tooltipPosition="right"
        />

        {this.renderAcademyButton()}

        {this.renderSiteMenu()}
      </div>
    );
  }

  renderAcademyButton() {
    return (
      <AcademyButton
        addClasses="academy-button"
        onClick={() => this.handleAcademyButtonClick()}
        tooltipContent="Brainscape Academy"
        tooltipPosition="right"
      />  
    );
  }

  renderFeatureOsButton() {
    const currentUser = this.props.currentUser;
    if (this.props.isLoadingUserInfo || !currentUser.flags.isPro) {
      return null;
    }

    return (
      <PeopleButton
        addClasses="people-button"
        onClick={() => this.handleFeatureOsButtonClick()}
        tooltipContent="Feature Requests Forum"
        tooltipPosition="right"
      />  
    );
  }

  renderMoneyAndOrGetAppButton() {
    const currentUser = this.props.currentUser;
    if (this.props.isLoadingUserInfo) {
      return null;
    }

    if (!currentUser.profile.hasMobileActivity && !currentUser.flags.isPro) {
      return (
        <PhoneButton
          addClasses="phone-button"
          onClick={() => this.handleGetTheAppButtonClick()}
          tooltipContent="Get the Brainscape app"
          tooltipPosition="right"
        />  
      )
    }

    if (currentUser.profile.hasMobileActivity && !currentUser.flags.isPro) {
      return (
        <MoneyButton
          onClick={() => this.handleMoneyButtonClick()}
          tooltipContent="Earn money from your flashcards"
          tooltipPosition="right"
        />
      )
    }

    return (
      <>
        <PhoneButton
          addClasses="phone-button"
          onClick={() => this.handleGetTheAppButtonClick()}
          tooltipContent="Get the Brainscape app"
          tooltipPosition="right"
        />  

        <MoneyButton
          onClick={() => this.handleMoneyButtonClick()}
          tooltipContent="Earn money from your flashcards"
          tooltipPosition="right"
        />
      </>
    )
  }

  renderEmptyLibrary() {
    return (
      <div className="empty-library-message">
        <p className="main-message">Your library is empty.</p>
        <p className="prompt">Search for classes to study by using the search icon above, or use the plus icon above to create your own class.</p>
      </div>
    )
  }

  renderNotificationsCount() {
    const notificationsCount = this.props.currentUser.stats.notificationsCount;

    if (!this.props.shouldShowNotificationsCount || !notificationsCount || notificationsCount == 0) {
      return null;
    }

    return (
      <div className="user-notifications-count">{notificationsCount}</div>
    );
  }

  renderSiteMenu() {
    return (
      <SiteMenu
        featureOsUrl={this.props.currentUser?.featureOsUrl}
        isOpen={this.state.isSiteMenuOpen}
        isAuthorizedCreator={this.props.currentUser?.groups?.includes('authorized_creator')}
        isUserBscAdmin={this.props.currentUser.flags.isBscAdmin}
        sidebarMode={this.props.sidebarMode}
        onCloseRequest={() => this.handleCloseSiteMenuRequest()}
      />
    );
  }

  renderSpinner() {
    return (
      <Spinner />
    );
  }

  renderTrendingCertifiedClassesTooltip() {
    return (
      <>
        <div className="body-sub-heading">What are Certified Classes?</div>
        <p className="body-text">These are classes created by Brainscape and our content partners.</p>
        <p className="body-text">They have been thoroughly reviewed for accuracy, comprehensiveness, and learning effectiveness.</p>
      </>
    );
  }

  renderTrendingPack(trendingPack, index) {
    return (
      <SidebarPack
        addClasses="trending-pack"
        currentUserId={this.props.currentUser.userId}
        id={`pack-id-${trendingPack.packId}`}
        isTrendingPack={true}
        isUserPack={false}
        isUserPro={this.props.currentUser.flags.isPro}
        key={trendingPack.packId}
        onAddTrendingPackRequest={this.handleAddTrendingPackRequest}
        onTrendingPackClick={(sidebarPack) => this.handleTrendingPackClick(sidebarPack)}
        onTrendingPackDetailRequest={this.props.onTrendingPackDetailRequest}
        sidebarPack={trendingPack}
      />
    );
  }

  renderTrendingPacks() {
    if (this.props.isLoadingTrendingPacks) {
      return (
        <div className="loading-packs">
          {this.renderSpinner()}
        </div>
      );
    }

    if (!this.props.trendingPacks || this.props.trendingPacks.length < 1) {
      return null;
    }

    let trendingPacks = this.props.trendingPacks.map((trendingPack, index) => {
      return this.renderTrendingPack(trendingPack, index);
    });

    return (
      <ul className="trending-packs">
        {trendingPacks}
      </ul>
    );
  }

  renderTrendingPacksFooter() {
    return (
      <footer className="trending-packs-footer">

        <SimpleTextButton
          addClasses='see-all-trending-packs-button'
          label={'See all Certified Classes'}
          onClick={this.handleSeeAllTrendingPacksButtonClick}
        />
      </footer>
    );
  }

  renderUpgradeButton() {
    if (this.props.isLoadingUserInfo || this.props.currentUser.flags.isPro) {
      return null;
    }

    return (
      <PillButton
        addClasses="pill-button-small upgrade-button tertiary"
        label="Upgrade"
        onClick={() => this.handleUpgradeButtonClick()}
      />
    );
  }

  renderUserPack(userPack, index) {
    const isSelected = (userPack.packId == this.props.currentPackId);
    const isCertifiedClass = userPack.certified ? 'is-certified' : '';
    const classes = toClassStr(['user-pack', isCertifiedClass]);

    return (
      <SidebarPack
        addClasses={classes}
        currentUserId={this.props.currentUser.userId}
        id={`pack-id-${userPack.packId}`}
        isMarketPack={!!userPack.certified}
        isRestrictedByPrivacy={this.props.isRestrictedByPrivacy}
        isSelected={isSelected}
        isTrendingPack={false}
        isUserPack={true}
        isUserPro={this.props.currentUser.flags.isPro}
        key={userPack.packId}
        onRemovePackRequest={this.handleRemovePackRequest}
        onUserPackClick={(sidebarPack) => this.handleUserPackClick(sidebarPack)}
        sidebarMode={this.props.sidebarMode}
        sidebarPack={userPack}
      />
    );
  }

  renderUserPacks() {
    const userPackCount = (this.props.userPacks?.length) ? this.props.userPacks.length : 0;

    if (this.props.isLoadingUserPacks && !this.props.isShowingCachedUserPacks && userPackCount < 1) {
      return (
        <div className="loading-packs">
          {this.renderSpinner()}
        </div>
      );
    }

    if (!this.props.userPacks || userPackCount < 1) {
      return this.renderEmptyLibrary();
    }

    let userPacks = this.props.userPacks.map((userPack, index) => {
      return this.renderUserPack(userPack, index);
    });

    return (
      <ul className="user-packs">
        {userPacks}
      </ul>
    );
  }

  renderUserPacksFilter() {
    return null; 

    if (this.props.isLoadingUserPacks || this.props.isEmptyLibrary || this.props.userPacks.length < this.ENABLE_FILTER_PACK_COUNT) {
      return null;
    }

    const hasTextClass = this.props.userPacksFilterText.length > 0 ? 'has-text' : '';
    const proRequiredClass = this.props.currentUser.flags.isPro ? '' : 'pro-required';
    const isShowingClass = this.props.shouldShowUserPacksFilter ? 'is-showing' : '';
    const classes = toClassStr(['filter-box', hasTextClass, proRequiredClass, isShowingClass]);

    return (
      <div className={classes}>
        <input
          className="filter-text-input"
          type="text"
          onChange={(e) => this.handleFilterBoxChange(e)}
          onClick={(e) => this.handleFilterBoxClick(e)}
          placeholder="Filter Classes"
          value={this.props.userPacksFilterText}
        />
        <ClearButton
          addClasses="clear-filter-text-button"
          onClick={() => this.handleClearFilterTextButtonClick()}
        />
        <RetractUpButton
          addClasses="retract-filter-text-button"
          onClick={() => this.handleRetractFilterTextButtonClick()}
        />
      </div>
    );
  }

  renderAddClassFooter() {
    if (!this.props.userPacks || this.props.isEmptyLibrary) {
      return null;
    }

    const classes = toClassStr(['add-class-footer']);

    return (
      <div className={classes}>
        <div className="add-class-option create-class-option" onClick={this.handleCreatePackButtonClick}>
          <div className="option-icon big-plus-button" />
          <div className="add-prompt create-prompt">Add New Class</div>
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddTrendingPackRequest = (packId) => {
    trackEvent('dashboard', 'trendingItem/add', {
      packId: packId,
    });

    categorySubscription.create(packId);
  };

  handleAcademyButtonClick = () => {
    UiHelper.openInNewTab('/academy');
  }

  handleClearFilterTextButtonClick() {
    this.props.onFilterUserPacksRequest('');
  }

  handleCloseSiteMenuRequest() {
    this.setState({
      isSiteMenuOpen: false,
    });
  }

  handleContractSidebarButtonClick = () => {
    this.triggerTooltipClose();
    this.triggerSidebarModeChangeRequest('mini');
  }

  handleCreatePackButtonClick = () => {
    this.triggerCreatePackRequest();
  }

  handleCurrentPackScrollToRequest = () => {
    this.scrollToCurrentPack();
  }

  handleDetailOverlayClosed = () => {
    this.setState({
      isSidebarModeMutable: true,
    });
  }

  handleDetailOverlayOpened = () => {
    this.setState({
      isSidebarModeMutable: false,
    });
  }

  handleFeatureOsButtonClick() {
    const featureOsUrl = this.props.currentUser.featureOsUrl || 'https://brainscape.featureos.app';
    UiHelper.openInNewTab(featureOsUrl);
  }

  handleExtendSidebarButtonClick = () => {
    this.triggerTooltipClose();
    this.triggerSidebarModeChangeRequest('full');
  }

  handleFilterBoxChange(e) {
    const filterText = e.target.value;
    this.props.onFilterUserPacksRequest(filterText);
  }

  handleFilterBoxClick(e) {
    e.stopPropagation();

    if (this.props.currentUser.flags.isPro) {
      return false;
    }

    this.triggerUpgradeModalOpen('Filter Classes', 'filter_classes', 'list-8');
  }

  handleFindClassButtonClick = () => {
    trackEvent('dashboard', 'findClassIconClick');
    UiHelper.openInNewTab('/subjects');
  }

  handleGetTheAppButtonClick() {
    this.triggerGetTheAppModalOpen({});
  }

  handleHeaderChange = () => {
    return false;
  }

  handleMoneyButtonClick() {
    if (this.props.currentUser?.groups?.includes('authorized_creator')) {
      window.location = '/pt/ep/earnings';
      return;
    }

    this.triggerEarnMoneyModalOpen();
  }

  handlePaginationButtonClick = () => {
    this.props.onPaginatePacksRequest();
  }

  handleRemovePackRequest = (sidebarPack) => {
    const aside = sidebarPack.isMarket ? '. (You can always re-add it using the find classes button)' : '';
    const actionText = 'remove the ' + sidebarPack.name + ' class from your library' + aside;
    const onResolution = () => this.removePack(sidebarPack.packId);

    this.triggerConfirmRemovePackModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove class',
      onResolution: onResolution,
    });
  }

  handleRetractFilterTextButtonClick() {
    const firstUserPack = document.querySelector('.sidebar-pack');
    firstUserPack.scrollIntoView();
  }

  handleSeeAllTrendingPacksButtonClick = () => {
    UiHelper.openInNewTab('/learn', 'market');
  }

  handleSiteMenuBruceButtonClick() {
    this.setState({
      isSiteMenuOpen: true,
    });
  }

  handleUpgradeButtonClick() {
    this.triggerUpgradeModalOpen('upgrade to Pro CTA', 'upgrade_cta');
  }

  handleUserAvatarClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const profilePath = `/profiles/${this.props.currentUser.profileId}`;

    UiHelper.openInNewTab(profilePath, 'profile');
  }

  handleUserAvatarMouseEnter = (e) => {
    const tooltipContent = `${this.props.currentUser.profile.fullName}'s Profile`;

    this.triggerTooltipOpen({
      content: tooltipContent,
      elem: this.userAvatarElem,
      position: 'right',
    });
  }

  handleUserAvatarMouseLeave = () => {
    this.triggerTooltipClose();
  }

  handleUserPackClick = (sidebarPack) => {
    this.triggerPackDetailViewRequest(sidebarPack.packId);

    if (this.props.isMobileViewportSize) {
      this.props.onHideClassesRequest();
    }
  }

  handleTrendingPackClick = (sidebarPack) => {
    trackEvent('dashboard', 'trendingItem/show', {
      packId: sidebarPack.packId,
    });

    category.show(sidebarPack.sourceId).then(category => {
      if (category.subjectPath) {
        UiHelper.openInNewTab(category.subjectPath, 'market');
      }
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmRemovePackModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerConfirmRemovePackModalUpdate(viewProps) {
    EventManager.emitEvent('caution-modal:update', viewProps);
  }

  triggerConfirmRemovePackModalClose() {
    EventManager.emitEvent('caution-modal:close', {});
  }

  triggerCreatePackRequest = () => {
    EventManager.emitEvent('create-pack-modal:open', {});
  }

  triggerEarnMoneyModalOpen() {
    EventManager.emitEvent('earn-money-modal:open', {});
  }

  triggerGetTheAppModalOpen() {
    EventManager.emitEvent('get-the-app-modal:open', {});
  }

  triggerPackDetailViewRequest = (packId, deckId=null, cardId=null, tabId='decks') => {
    EventManager.emitEvent('pack-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerPageLoadingOverlayClose() {
    EventManager.emitEvent('page-loading-overlay:close', {});
  }

  triggerPageLoadingOverlayOpen(message) {
    EventManager.emitEvent('page-loading-overlay:open', {
      message: message,
    });
  }

  triggerRemoveUserPackRequest = (pack) => {
    EventManager.emitEvent('pack:remove-request', pack);
  }

  triggerSidebarModeChangeRequest = (mode) => {
    EventManager.emitEvent('sidebar:mode-change-request', {sidebarMode: mode});
  }

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  }

  triggerUpgradeModalOpen = (desiredAction, paywall, featuresList) => {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  removePack = (packId) => {
    userPack.remove(packId);
  }

  scrollToCurrentPack = () => {
    if (this.props.isLoadingUserPacks && !this.props.isShowingCachedUserPacks) {
      return false;
    }

    const packRowId = `#pack-id-${this.props.currentPackId}`;
    this.scrollToPackRowById(packRowId);
  }

  scrollToPackRowById(id) {
    const currentPackRow = document.querySelector(id);

    if (currentPackRow) {
      scrollIntoView(currentPackRow, {
        scrollMode: 'if-needed',
        block: 'start',
      });
    }
  }
}

DashboardSidebar.propTypes = PT;

export default DashboardSidebar;
