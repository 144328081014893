
// substrate and utils
import CookieHelper                     from '_utils/CookieHelper';
import EventManager                     from '@brainscape/event-manager';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import SessionStorageHelper             from '_utils/SessionStorageHelper';
import UiHelper                         from '_utils/UiHelper';
              
// models
import packDeckReordering               from '_models/packDeckReordering';
import packMetadata                     from '_models/packMetadata';
import userLocalStore                   from '_models/userLocalStore';

// concerns
import currentPackConcern               from '_concerns/currentPackConcern';
import currentPackDecksConcern          from '_concerns/currentPackDecksConcern';
import currentPackLearnersConcern       from '_concerns/currentPackLearnersConcern';
import currentPackLeaderboardConcern    from '_concerns/currentPackLeaderboardConcern';
import currentPackMetadataConcern       from '_concerns/currentPackMetadataConcern';
import estimatedTimeLeftConcern         from '_concerns/estimatedTimeLeftConcern';
import trendingPacksConcern             from '_concerns/trendingPacksConcern';

// views
import PackDetailPage                   from '_pack-detail/desktop/PackDetailPage';
import PackDetailScreen                 from '_pack-detail/mobile/PackDetailScreen';


const PT = {  
  initialPackId:              PropTypes.node,
  initialTabId:               PropTypes.string,
  initialUser:                PropTypes.object,
  initialUserPacks:           PropTypes.array,
  initialView:                PropTypes.string, 
  isLoadingUser:              PropTypes.bool,
  isLoadingUserPacks:         PropTypes.bool,
  isShowingCachedUserPacks:   PropTypes.bool,
};

const VALID_TAB_IDS = ['about', 'decks', 'learners', 'leaderboard'];


class PackDetailController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCardId: null,
      currentDeckId: null,
      currentPack: {},
      currentPackId: props.initialPackId,
      currentTabId: props.initialTabId || 'decks',
      estimatedTimeLeft: null,
      isFtue: false, // we wait to implement props.isFtue until we retrieve view data
      isLoadingPack: true,
      isLoadingPackDecks: true,
      isLoadingPackLeaderboard: false,
      isLoadingPackLearners: false,
      isLoadingPackMetadata: false,
      isLoadingTrendingPacks: true,
      isRestrictedByPrivacy: false,
      isMobileSidebarOpen: false,
      isMobileViewportSize: null,
      isShowingCachedPackLearners: false,
      sidebarMode: null,
      studyContext: {
        scope: 'pack',
        packId: props.initialPackId,
      },
      totalTimeStudied: 0,
      trendingPacks: null,
    };

    this.events = new EventManager();

    this.createDeckModalTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
    this.manageViewport();
    this.initCurrentResources();
    this.startHistoryMonitor();
    this.startWindowResizeMonitor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialPackId != prevProps.initialPackId) {
      this.setCurrentPackById(this.props.initialPackId);
    }

    if (prevProps.isFtue && !this.props.isFtue) {
      this.setState({
        isFtue: false,
      });
    }
  }

  componentWillUnmount() {
    this.stopWindowResizeMonitor();
    this.stopHistoryMonitor();
    this.unsubscribeToEvents();
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   INITIALIZE CORE RESOURCES
  ==================================================
  */

  initCurrentResources = () => {
    const packId = this.props.initialPackId;

    this.initCurrentPackData(packId);
    this.initTrendingPacksData();

    this.initSidebar();
  }

  initCurrentPackData = (packId, deckId=null, tabId=null) => {
    if (!packId) {
      this.setState({
        currentPackId: null,
        isLoadingPack: false,
        isLoadingPackDecks: false,
      });

      return false;
    }

    currentPackConcern.get(packId).then(currentPackData => {
      if (!this._isMounted) {
        return false;
      }
  
      const currentPack = currentPackData.currentPack;
      const isRestrictedByPrivacy = this.isRestrictedByPrivacy(currentPack);

      this.setState({...currentPackData, 
        currentDeckId: deckId,
        currentPackId: packId,
        isLoadingPack: false,
        isLoadingPackDecks: !isRestrictedByPrivacy,
        isRestrictedByPrivacy: isRestrictedByPrivacy,
      });

      if (isRestrictedByPrivacy) {
        return null;
      }

      this.initCurrentPackDecks(currentPack);
      this.initCurrentPackSectionData(currentPack);

      this.ensureRequiredMetadata(currentPack);
      this.pushResourceToHistory();
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentPackDecks = (currentPack) => {
    const userId = this.props.initialUser.userId;

    currentPackDecksConcern.get(userId, currentPack).then(currentPackData => {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack}, 
        isLoadingPackDecks: false,
      }, () => {
        this.manageFtue(); // at this point we have enough data to display FTUE if needed
      });
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentPackSectionData = (currentPack) => {
    const currentTabId = this.verifyCurrentTabId(this.state.currentTabId, currentPack);

    switch (currentTabId) {
      case 'about':
        this.initCurrentPackMetadata(currentPack);
      break;
      case 'learners':
        this.initCurrentPackLearners(currentPack);
      break;
      case 'leaderboard':
        this.initCurrentPackLeaderboard(currentPack);
      break;
    }
  }

  initCurrentPackMetadata = (currentPack) => {
    this.setState({
      isLoadingPackMetadata: true,
    });

    currentPackMetadataConcern.get(currentPack).then(currentPackData => {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack}, 
        isLoadingPackMetadata: false,
      });
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentPackLearners = (currentPack) => {
    const userId = this.props.initialUser.userId;

    this.setState({
      isLoadingPackLearners: true,
    });

    currentPackLearnersConcern.get(userId, currentPack).then(currentPackData => {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack}, 
        currentTabId: 'learners',
        isLoadingPackLearners: false,
      });
    }).catch(err => {
      console.error(err);
    });
  }

  initCurrentPackLeaderboard = (currentPack) => {
    const userId = this.props.initialUser.userId;

    this.setState({
      isLoadingPackLeaderboard: true,
    });

    currentPackLeaderboardConcern.get(currentPack).then(currentPackData => {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack}, 
        currentTabId: 'leaderboard',
        isLoadingPackLeaderboard: false,
      });
    }).catch(err => {
      console.error(err);
    });
  }

  ensureRequiredMetadata = (currentPack) => {
    if (currentPack.permission != 'admin') {
      return false;
    }

    if (currentPack.flags.isCertified) {
      return false;
    }

    if (this.props.initialUser?.flags?.isFtue) {
      return false;
    }

    const userId = this.props.initialUser.userId
    const packId = currentPack.packId;

    if (currentPackMetadataConcern.hasRequiredMetadata(userId, packId)) {
      return false;
    }

    currentPackMetadataConcern.get(currentPack).then(packData => {
      const metadata = packData.currentPack.metadata;
      const role = packMetadata.getRole(metadata);
      const purpose = packMetadata.getPurpose(metadata);

      if (!(role && purpose)) {
        this.triggerRequiredMetadataModalOpen(metadata, currentPack);
      } else {
        currentPackMetadataConcern.setPackMetadataStatus(userId, packId, true);
      }
    }).catch(err => {
      console.error(err);
    });
  }

  initTrendingPacksData = () => {
    trendingPacksConcern.fetch()
      .catch(err => {
        console.error(err);
        this.handleTrendingPacksError(err);
      });
  }

  initSidebar = () => {
    this.setState({
      sidebarMode: this.getSidebarMode(),
    })
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('card-confidence:updated',            this.handleCardConfidenceUpdated);
    this.events.addListener('category-subscription:created',      this.handleCategorySubscriptionCreated);
    this.events.addListener('current-card:change-request',        this.handleCurrentCardChangeRequest);
    this.events.addListener('current-deck:change-request',        this.handleCurrentDeckChangeRequest);
    this.events.addListener('current-tab:change-request',         this.handleCurrentTabChangeRequest);
    this.events.addListener('deck:created',                       this.handleDeckCreated);
    this.events.addListener('deck:imported',                      this.handleDeckImported);
    this.events.addListener('deck:removed',                       this.handleDeckRemoved);
    this.events.addListener('deck:updated',                       this.handleDeckUpdated);
    this.events.addListener('deck-cards:generated',               this.handleDeckCardsGenerated);
    this.events.addListener('deck-confidences:reset',             this.handleDeckConfidencesReset);
    this.events.addListener('deck-duplicate:job-completed',       this.handleDeckDuplicated);
    this.events.addListener('deck-selections:updated',            this.handlePackDeckSelectionsUpdated);
    this.events.addListener('learner-filters:updated',            this.handleLearnerFiltersUpdated);
    this.events.addListener('learner-selections:updated',         this.handleLearnerSelectionsUpdated);
    this.events.addListener('learner-sorters:updated',            this.handleLearnerSortersUpdated);
    this.events.addListener('pack:removed',                       this.handlePackRemoved);
    this.events.addListener('pack:retrieved',                     this.handlePackRetrieved);
    this.events.addListener('pack:updated',                       this.handlePackUpdated);
    this.events.addListener('pack-confidences:reset',             this.handlePackConfidencesReset);
    this.events.addListener('pack-deck-cards:generated',          this.handlePackDeckCardsGenerated);
    this.events.addListener('pack-decks:reorder-request',         this.handlePackDecksReorderRequest);
    this.events.addListener('pack-decks:reordered',               this.handlePackDecksReordered);
    this.events.addListener('pack-decks:retrieved',               this.handlePackDecksRetrieved);
    this.events.addListener('pack-leaderboard:retrieved',         this.handlePackLeaderboardRetrieved);
    this.events.addListener('pack-learner:removed',               this.handlePackLearnerRemoved);
    this.events.addListener('pack-learner:updated',               this.handlePackLearnerUpdated);
    this.events.addListener('pack-learners:page-received',        this.handlePackLearnersPageReceived);
    this.events.addListener('pack-learners:retrieved',            this.handlePackLearnersRetrieved);
    this.events.addListener('pack-metadata:retrieved',            this.handlePackMetadataRetrieved);
    this.events.addListener('pack-metadata:updated',              this.handlePackMetadataUpdated);
    this.events.addListener('sidebar:mode-change-request',        this.handleSidebarModeChangeRequest);
    this.events.addListener('trending-packs:retrieved',           this.handleTrendingPacksRetrieved);
    this.events.addListener('user-pref:updated',                  this.handleUserPrefUpdated);
  } 

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    // if (this.state.isMobileViewportSize) {
    //   return this.renderMobileScreen();
    // }

    return this.renderDesktopPage();
  }

  renderDesktopPage() {
    return (
      <PackDetailPage
        currentDeckId={this.state.currentDeckId}
        currentPack={this.state.currentPack}
        currentPackId={this.state.currentPackId}
        currentUser={this.props.initialUser}
        currentUserPacks={this.props.initialUserPacks}
        currentTabId={this.state.currentTabId}
        estimatedTimeLeft={this.state.estimatedTimeLeft}
        handleHideClassesRequest={() => this.triggerMobileClassesOverlayClose()}  // TODO: Refactor this with Karen
        handleShowClassesRequest={() => this.triggerMobileClassesOverlayOpen()}  // TODO: Refactor this with Karen
        isFtue={this.state.isFtue}
        isLoadingPack={this.state.isLoadingPack}
        isLoadingPackDecks={this.state.isLoadingPackDecks}
        isLoadingPackLeaderboard={this.state.isLoadingPackLeaderboard}
        isLoadingPackLearners={this.state.isLoadingPackLearners}
        isLoadingPackMetadata={this.state.isLoadingPackMetadata}
        isLoadingTrendingPacks={this.state.isLoadingTrendingPacks}
        isLoadingUser={this.props.isLoadingUser}
        isLoadingUserPacks={this.props.isLoadingUserPacks}
        isRestrictedByPrivacy={this.state.isRestrictedByPrivacy}
        isShowingCachedPackLearners={this.state.isShowingCachedPackLearners}
        isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
        isMobileViewportSize={this.state.isMobileViewportSize}
        isMobileSidebarOpen={this.state.isMobileSidebarOpen}
        sidebarMode={this.state.sidebarMode}
        totalTimeStudied={this.state.totalTimeStudied}
        trendingPacks={this.state.trendingPacks}
      />
    );
  }

  renderMobileScreen() {
    return (
      <PackDetailScreen
        currentDeckId={this.state.currentDeckId}
        currentPack={this.state.currentPack}
        currentUser={this.props.initialUser}
        currentUserPacks={this.props.initialUserPacks}
        currentTabId={this.state.currentTabId}
        isLoadingPack={this.state.isLoadingPack}
        isLoadingPackDecks={this.state.isLoadingPackDecks}
        isLoadingPackLeaderboard={this.state.isLoadingPackLeaderboard}
        isLoadingPackLearners={this.state.isLoadingPackLearners}
        isLoadingPackMetadata={this.state.isLoadingPackMetadata}
        isLoadingTrendingPacks={this.state.isLoadingTrendingPacks}
        isLoadingUser={this.props.isLoadingUser}
        isLoadingUserPacks={this.props.isLoadingUserPacks}
        isRestrictedByPrivacy={this.state.isRestrictedByPrivacy}
        isShowingCachedPackLearners={this.state.isShowingCachedPackLearners}
        isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
        sidebarMode={this.state.sidebarMode}
        totalTimeStudied={this.state.totalTimeStudied}
        trendingPacks={this.state.trendingPacks}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardConfidenceUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackConcern.handleCardConfidenceUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handleCategorySubscriptionCreated = (eventData) => {  
    const category = eventData.category;

    this.setState({
      currentPackId: category.packId,
    }, () => {
      this.initCurrentPackData(this.state.currentPackId);
      this.initTrendingPacksData();
    });
  }

  handleCurrentCardChangeRequest = (eventData) => {    
    this.setState({
      currentCardId: eventData.cardId,
    });
  }

  handleCurrentDeckChangeRequest = (eventData) => {
    this.setState({
      currentDeckId: eventData.deckId,
      currentTabId: eventData.tabId || 'decks',
    });
  }

  handleCurrentTabChangeRequest = (eventData) => {
    this.setCurrentTabById(eventData.tabId);
  } 

  handleDeckCardsGenerated = (eventData) => {
    const {packId, deckId, newCardCount} = eventData;
    const postRenderCallback = () => this.triggerToastOpen(`${newCardCount} cards added`, 'success');

    this.triggerDeckDetailViewRequest({packId, deckId, cardId: null, tabId: 'edit', postRenderCallback});
  }

  handleDeckConfidencesReset = (eventData) => {
    if (eventData.packId == this.state.currentPackId) {
      this.initCurrentPackData(this.state.currentPack.packId);
      this.triggerToastOpen('Deck stats reset', 'success');
    }
  }

  handleDeckCreated = (eventData) => {
    if (eventData.packId == this.state.currentPackId) {
      this.initCurrentPackData(this.state.currentPack.packId, eventData.deckId);
    }
  }

  handleDeckImported = (eventData) => {
    if (eventData.packId == this.state.currentPackId) {
      this.initCurrentPackData(this.state.currentPack.packId);
    }
  }

  handleDeckRemoved = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackDecksConcern.handleDeckRemoved(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handleDeckDuplicated = (eventData) => {
    currentPackDecksConcern.fetch(this.props.initialUser.userId, this.state.currentPack);
    this.triggerToastOpen('Deck Duplicated');
  }
  
  handleDeckUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackDecksConcern.handleDeckUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      }, () => {
        this.triggerToastOpen('Deck updated', 'success');
      });
    }
  }

  handleDetailOverlayClosed = () => {
    this.setState({
      isSidebarModeMutable: true,
    });
  }

  handleDetailOverlayOpened = () => {
    this.setState({
      isSidebarModeMutable: false,
    });
  }

  handleLearnerFiltersUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackLearnersConcern.handleLearnerFiltersUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handleLearnerSelectionsUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackLearnersConcern.handleLearnerSelectionsUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handleLearnerSortersUpdated = (eventData) => {    
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackLearnersConcern.handleLearnerSortersUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handlePackConfidencesReset = (eventData) => {
    if (eventData.packId == this.state.currentPackId) {
      this.initCurrentPackData(this.state.currentPack.packId);
      this.triggerToastOpen('Class stats reset', 'success');
    }
  }

  handlePackDeckCardsGenerated = (eventData) => {
    const {packId, deckId, newCardCount} = eventData;
    const postRenderCallback = () => this.triggerToastOpen(`${newCardCount} cards added`, 'success');

    this.triggerDeckDetailViewRequest({packId, deckId, cardId: null, tabId: 'edit', postRenderCallback});
  }

  handlePackDeckSelectionsUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackDecksConcern.handlePackDeckSelectionsUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handlePackDecksReordered = (eventData) => {
    const {currentPackId, currentPack} = this.state;

    if (!(eventData.packId == currentPackId)) {
      return false;
    }

    const currentPackDecksData = currentPackDecksConcern.handlePackDecksReordered(currentPack, eventData);

    if (currentPackDecksData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackDecksData.currentPack},
        currentPackId: currentPackDecksData.currentPackId,
      }, () => {
        this.triggerToastOpen('Decks reordered', 'success');
      });
    }
  }

  handlePackDecksReorderRequest = (eventData) => {
    const {currentPackId, currentPack} = this.state;

    if (!(eventData.packId == currentPackId)) {
      return false;
    }

    const currentPackDecksData = currentPackDecksConcern.handlePackDecksReorderRequest(currentPack, eventData);

    if (currentPackDecksData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackDecksData.currentPack},
        currentPackId: currentPackDecksData.currentPackId,
      }, () => {
        packDeckReordering.create(currentPackId, eventData.reorderedDeckIds);
      });
    }
  }

  handlePackDecksRetrieved = (eventData) => {
    const augmentedPack = eventData.currentPack;

    const packDecksData = {
      decks: augmentedPack.decks,
      deckIds: augmentedPack.deckIds,
      localDeckTransforms: augmentedPack.localDeckTransforms,
      transformedStats: augmentedPack.transformedStats,
    };

    const currentPack = {...this.state.currentPack, ...packDecksData};

    this.setState({
      currentPack: currentPack,
      isLoadingPackDecks: false,
    }, () => {
      this.calculateEstimatedTimeLeft();
    });
  }

  handlePackLeaderboardRetrieved = (eventData) => {
    const augmentedPack = eventData.currentPack;
    
    const packLeaderboardData = {
      leaderboard: augmentedPack.leaderboard,
      leaderboardOrder: augmentedPack.leaderboardOrder,
      learners: null,
      learnerIds: null,
      transformedLearnerIds: null,
    };

    const currentPack = {...this.state.currentPack, ...packLeaderboardData};

    this.setState({
      currentPack: currentPack,
      isLoadingPackLeaderboard: false,
    });
  }

  handlePackLearnersPageReceived = (eventData) => {
    /*
      NOTE: Pack Learners are auto-paginated. This method receives each page of the entire set. As soon as we get the first page of Pack learners, we update the 'above the fold' UI. The newModel.paginatedIndex continues to retrieve any remaining pages in the background. When all pages of the index are received, the paginatedIndex promise will resolve with a set of all Pack learners, at which time we will finish display below the fold with the 'handlePackLearnersRetrieved' method.
    */

    if (this.state.isShowingCachedPackLearners) {
      return false;
    }

    if (eventData.page > 1) {
      // in the current UI, we take page 1 and render to the user. We wait to receive the whole set before rendering again.
      return false;
    }

    const currentPack = {...this.state.currentPack};
    currentPack.learners = eventData.learners;
    currentPack.learnerIds = currentPack.learners.map(learner => learner.userId);

    this.setState({
      currentPack: currentPack,
      isLoadingPackLearners: true,
    });
  }

  handlePackLearnersRetrieved = (eventData) => {
    /*
      NOTE: Pack Learners are auto-paginated. This method receives either: first, a cached copy of all Pack Learners (if it is available in Session Storage) from the currentPackLearnersConcern, and then, in either case, a copy of all Pack Learners (after pagination is complete). If there is no cached copy available, the UI will be updated with the first page of Deck Cards via the 'handlePackLearnersPageReceived' method which receives each paginated page from the server.
    */

    const augmentedPack = {...eventData.currentPack};
    augmentedPack.learnerIds = augmentedPack.learners.map(learner => learner.userId);
    
    const packLearnersData = {
      learners: augmentedPack.learners,
      learnerIds: augmentedPack.learnerIds,
      localLearnerTransforms: augmentedPack.localLearnerTransforms,
      transformedLearnerIds: augmentedPack.transformedLearnerIds,
      metadata: null,
      leaderboard: null,
      leaderboardOrder: null,
    };

    const currentPack = {...this.state.currentPack, ...packLearnersData};

    this.setState({
      currentPack: currentPack,
      isLoadingPackLearners: eventData.isFromCache,
      isShowingCachedPackLearners: eventData.isFromCache,
    });
  }

  handlePackLearnerRemoved = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackLearnersConcern.handlePackLearnerRemoved(this.props.initialUser, this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handlePackLearnerUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackLearnersConcern.handlePackLearnerUpdated(this.props.initialUser, this.state.currentPack, this.state.currentTabId, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handlePackMetadataRetrieved = (eventData) => {    
    const augmentedPack = eventData.currentPack;
    
    const packMetadataData = {
      metadata: augmentedPack.metadata,
      leaderboard: null,
      leaderboardOrder: null,
      learners: null,
      learnerIds: null,
      transformedLearnerIds: null,
    };

    const currentPack = {...this.state.currentPack, ...packMetadataData};

    this.setState({
      currentPack: {...this.state.currentPack, ...eventData.currentPack},
      isLoadingPackMetadata: false,
    });
  }

  handlePackMetadataUpdated = (eventData) => {
    if (eventData.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackMetadataConcern.handlePackMetadataUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      });
    }
  }

  handlePackRemoved = (eventData) => {
    if (!(this.props.initialUserPacks && this.props.initialUserPacks.length > 1)) {
      UiHelper.navigate('/');
      return false;
    }

    const removedPackId = eventData.packId;

    if (removedPackId == this.state.currentPackId) {
      this.selectAdjacentPack(removedPackId);
    }
  }

  handlePackRetrieved = (eventData) => {
    const currentPack = {...this.state.currentPack, ...eventData.currentPack};

    this.setState({
      currentPack: currentPack,
      isLoadingPack: false,
    }, () => {
      this.pushResourceToHistory();
    });
  }

  handlePackUpdated = (eventData) => {
    if (eventData.pack.packId != this.state.currentPackId) {
      return false;
    }

    const currentPackData = currentPackConcern.handlePackUpdated(this.state.currentPack, eventData);

    if (currentPackData) {
      this.setState({
        currentPack: {...this.state.currentPack, ...currentPackData.currentPack},
      }, () => {
        this.triggerToastOpen('Class updated', 'success');
      });
    }
  }

  handleSidebarModeChangeRequest = ({sidebarMode}) => {
    this.setState({
      sidebarMode: sidebarMode,
    });

    userLocalStore.setUserLocalPref(this.props.initialUser.userId, 'dashboardSidebarModePref', sidebarMode);
  }

  handleTrendingPacksError = (err) => {
    this.setState({
      isLoadingTrendingPacks: false,
      trendingPacks: [],
    });
  };

  handleTrendingPacksRetrieved = (eventData) => {
    this.setState({
      isLoadingTrendingPacks: false,
      trendingPacks: eventData.trendingPacks,
    });
  }

  handleUserPrefUpdated = (prefData) => {
    switch (prefData.prefKey) {
      // case 'deckClickAction':
      //   this.setState({
      //     deckClickAction: prefData.value,
      //   });
      // break;
    }
  }

  handleWindowResize = (e) => {
    this.manageViewport();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentPackScrollToRequest = () => {
    EventManager.emitEvent('current-pack:scroll-to-request', {});
  }

  triggerDeckDetailViewRequest = (viewProps) => {
    EventManager.emitEvent('deck-detail-view:change-request', {
      packId: viewProps.packId,
      deckId: viewProps.deckId,
      cardId: viewProps.cardId || null,
      tabId:  viewProps.tabId  || 'preview',
      postRenderCallback: viewProps.postRenderCallback || null,
    });
  }

  triggerPackDetailViewRequest = (packId, deckId=null, cardId=null, tabId='decks') => {
    EventManager.emitEvent('pack-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerRequiredMetadataModalOpen = (metadata, pack) => {
    EventManager.emitEvent('required-metadata-modal:open', {
      metadata: metadata,
      pack: pack,
      userId: this.props.initialUser.userId,
    });
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  // TODO: Refactor this with Karen
  triggerMobileClassesOverlayClose = () => {
    this.setState(
      {
        isMobileSidebarOpen: false,
      }
    );
  }

  // TODO: Refactor this with Karen
  triggerMobileClassesOverlayOpen = () => {
    this.setState(
      {
        isMobileSidebarOpen: true,
        sidebarMode: 'full',
      },
      () => {
        this.scrollSidebarToCurrentPack();
      },
    );
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  calculateEstimatedTimeLeft = () => {
    const {currentPackId, currentPack} = this.state;

    estimatedTimeLeftConcern.get(currentPackId, currentPack.transformedStats.cardCount, currentPack.transformedStats.levelCounts).then(resData => {
      this.setState({
        estimatedTimeLeft: resData.estimatedTimeLeft,
        totalTimeStudied: resData.totalTimeStudied,
      });
    }).catch(err => {
      console.log('something went wrong with estimatedTimeLeftConcern.get. err:', err);

      this.setState({
        estimatedTimeLeft: null,
        totalTimeStudied: 0,
      });
    });
  }

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.createDeckModalTimeout);
  }

  getSidebarMode = () => {
    if (this.props.initialUser?.flags?.isFtue) {
      return 'mini';
    }

    if (this.props.initialUser?.userId) {
      const localPrefMode = userLocalStore.getUserLocalPref(this.props.initialUser.userId, 'dashboardSidebarModePref',
      );

      if (localPrefMode) {
        return localPrefMode;
      }
    }

    const cookieMode = CookieHelper.getCookie('dashboard_sidebar_mode');

    if (cookieMode) {
      return cookieMode;
    }

    return 'full';
  }
  
  hasArrayData = (resource) => {
    return (resource && !this.isEmptyArray(resource));
  }

  hasObjectData = (resource) => {
    return (resource && !this.isEmptyObject(resource));
  }

  isEmptyArray = (arr) => {
    return (arr.length == 0);
  }

  isEmptyObject = (obj) => {
    return (Object.keys(obj).length == 0);
  }

  isRestrictedByPrivacy = (currentPack) => {
    if (!currentPack.flags?.isPrivate) return false;
    if (!this.props.initialUser.flags?.isPro) return true;
    if (currentPack.discoveryMedium == 'discovered') return true;

    return false;
  }

  manageFtue = () => {
    if (this.props.isFtue) {
      this.setState({
        isFtue: true,
      });
    }
  }

  manageUiTriggers = () => {
    // TODO: Move into a concern? Especially after expanding to manage banners, etc
    const currentUserUiTriggers = this.props.initialUser?.uiTriggers;

    if (!currentUserUiTriggers) {
      return false;
    }
    
    if (!currentUserUiTriggers.modals) {
      return false;
    }
    
    const modalTriggerKeys = Object.keys(currentUserUiTriggers.modals);

    if (!modalTriggerKeys) {
      return false;
    }
  }

  manageViewport = () => {
    UiHelper.adjustViewportHeight();
    const isMobileViewportSize = UiHelper.detectIfMobileSize();

    this.setState({
      isMobileViewportSize: isMobileViewportSize,
    });
  }

  popResourceFromHistory = (e) => {
    if (!this._isMounted) {
      return false;
    }

    e.stopPropagation();

    const historyObj = e.state;

    if (historyObj.packId != this.state.currentPackId) {
      const {packId, deckId, cardId, tabId} = historyObj;
      this.triggerPackDetailViewRequest(packId, deckId, cardId, tabId);

      return historyObj.path;
    }

    if (historyObj.deckId != this.state.currentDeckId) {
      const {packId, deckId, cardId, tabId} = historyObj;
      this.triggerDeckDetailViewRequest({packId, deckId, cardId, tabId});

      return historyObj.path;
    }

    if (historyObj.cardId != this.state.currentCardId) {
      const {packId, deckId, cardId, tabId} = historyObj;
      this.triggerDeckDetailViewRequest({packId, deckId, cardId, tabId});

      return historyObj.path;
    }

    if (historyObj.tabId != this.state.currentTabId) {
      const eventData = {
        tabId: historyObj.tabId,
      };

      this.handleCurrentTabChangeRequest(eventData);

      return historyObj.path;
    }

    return null;
  };

  pushResourceToHistory = () => {
    if (!this._isMounted) {
      return false;
    }

    if (!(this.props.initialUserPacks && this.props.initialUserPacks.length > 0)) {
      return false;
    }

    const pack = this.state.currentPack;
    const search = window.location.search;
    const path = (pack.paths?.dashboardPath) ? pack.paths.dashboardPath + '/' + this.state.currentTabId + search : '/';

    const currentPath = window.history?.state?.path;

    if (path != currentPath) {
      window.history.pushState({
        packId: pack ? pack.packId : null,
        tabId: this.state.currentTabId,
        path: path,
      }, null, path);

      this.updateLastPackCookies(pack.packId);
    }
  }

  // TODO: Refactor this with Karen
  scrollSidebarToCurrentPack() {
    const currentSidebarPack = document.querySelector(
      '.dashboard-sidebar .sidebar-pack.is-selected',
    );

    if (currentSidebarPack) {
      currentSidebarPack.scrollIntoView();
    }
  }

  selectAdjacentPack = (referencePackId) => {
    const userPacks = this.props.initialUserPacks;
    const packCount = userPacks.length;

    const referenceIndex = userPacks.findIndex(userPack => {
      return (userPack.packId == referencePackId);
    });

    const adjacentIndex = (referenceIndex == 0) ? 1 : Math.min(packCount - 2, Math.max(0, referenceIndex - 1));
    const adjacentPack = userPacks[adjacentIndex];

    this.setCurrentPack(adjacentPack);
  }

  setCurrentPack = (pack) => {
    this.setState({
      currentPack: pack,
      currentPackId: pack.packId,
      currentTabId: 'decks',
      isLoadingPack: true,
      isLoadingPackDecks: true,
    }, () => {
      this.initCurrentPackData(this.state.currentPack.packId);
      this.pushResourceToHistory();
    });
  }

  setCurrentPackById = (packId) => {
    this.setState({
      currentPackId: packId,
      currentTabId: 'decks',
      isLoadingPack: true,
      isLoadingPackDecks: true,
    }, () => {
      this.initCurrentPackData(packId);
    });
  }

  setCurrentTabById = (tabId) => {
    const userId = this.props.initialUser.userId;
    const currentPack = this.state.currentPack;
    const currentTabId = this.verifyCurrentTabId(tabId, currentPack);

    this.setState({
      currentTabId: currentTabId,
    }, () => {
      this.pushResourceToHistory();

      switch (currentTabId) {
        case 'decks':
          return true; // we always have pack decks data
        break;
        case 'about':
          if (!this.hasObjectData(currentPack.metadata)) {
            currentPackMetadataConcern.get(currentPack);
          }
        break;
        case 'learners':
          if (!this.hasArrayData(currentPack.learners)) {
            currentPackLearnersConcern.get(userId, currentPack);
          }
        break;
        case 'leaderboard':
          if (!this.hasArrayData(currentPack.leaderboard)) {
            currentPackLeaderboardConcern.get(currentPack);
          }
        break;
      }
    });
  }

  startHistoryMonitor() {
    window.addEventListener('popstate', (e) => this.popResourceFromHistory(e));
  }

  stopHistoryMonitor() {
    window.removeEventListener('popstate', (e) =>
      this.popResourceFromHistory(e),
    );
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  updateLastPackCookies(packId) {
    const now = new Date();

    CookieHelper.setCookie('last_pack_id', packId.toString(), 365);
    CookieHelper.setCookie('last_pack_id_date', now.toUTCString(), 365);
  }

  verifyCurrentTabId = (tabId='decks', pack) => {
    const currentPack = pack || this.state.currentPack;

    if (!currentPack) {
      return 'decks';
    }

    let verifiedTabId = tabId;

    if (VALID_TAB_IDS.indexOf(verifiedTabId) == -1) {
      return 'decks';
    }

    if (verifiedTabId == 'learners' || verifiedTabId == 'leaderboard') {
      verifiedTabId = (pack.flags.isCertified) ? 'leaderboard' : 'learners';
    }

    return verifiedTabId;
  }  
}

PackDetailController.propTypes = PT;

export default PackDetailController;
