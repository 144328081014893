
import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:         PropTypes.string,
  isDisabled:         PropTypes.bool,
  isGlyph:            PropTypes.bool,
  isProcessing:       PropTypes.bool,
  onClick:            PropTypes.func,
  title:              PropTypes.string,
  tooltipContent:     PropTypes.node,
  tooltipPosition:    PropTypes.string,  
}

class MakeFlashcardsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const isGlyphClass = this.props.isGlyph ? 'is-glyph' : '';
    const isProcessingClass = this.props.isProcessing ? 'is-processing' : '';

    let classes = toClassStr(['icon-button', 'make-flashcards-button', hasTooltipClass, isDisabledClass, isGlyphClass, isProcessingClass, this.props.addClasses,]);

    return (
      <div
        className={classes}
        onClick={this.handleButtonClick}
        onMouseEnter={this.handleButtonMouseEnter}
        onMouseLeave={this.handleButtonMouseLeave}
        title={this.props.title}
        ref={(elem) => { this.elem = elem }} 
      >
        <div className="icons">
          <i className="squares-icon" />
          <i className="plus-icon" />
        </div>

        {this.renderSpinner()}
      </div>
    );
  }

  renderSpinner() {
    if (this.props.isGlyph) {
      return null;
    }

    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick = (e) => {
    if (this.props.isGlyph) {
      return;     
    }

    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleButtonMouseEnter = (e) => {
    if (this.props.isGlyph) {
      return;     
    }
    
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleButtonMouseLeave = (e) => {
    if (this.props.isGlyph) {
      return;     
    }
    
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

MakeFlashcardsButton.propTypes = PT;

export default MakeFlashcardsButton;
