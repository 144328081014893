
// substrate and utils
import EventManager                   from '@brainscape/event-manager';
import React, { Fragment }            from 'react';
import scrollIntoView                 from 'scroll-into-view-if-needed';
import {toClassStr}                   from '_utils/UiHelper';

// models
import packDeckTransform              from '_models/packDeckTransform';
import userLocalStore                 from '_models/userLocalStore';

// sub-components
import DeckList                       from '_views/pack-detail/desktop/decks/DeckList';
import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import LabeledToggleButton            from '_views/shared/LabeledToggleButton';
import LoadingOverlay                 from '_views/shared/LoadingOverlay';
import MakeFlashcardsButton           from '_views/shared/MakeFlashcardsButton';
import RoundCheckbox                  from '_views/shared/RoundCheckbox';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import SvgIcon                        from '_views/shared/SvgIcon';
import RichContentTooltip             from '_views/shared/RichContentTooltip';
import StudyButton                    from '_views/shared/StudyButton';

import {
  CircledAddButton,
  ImportButton,
  InfoButton,
}                                     from '_views/shared/IconButton';

import {
  CircledAddIconTextButton,
}                                     from '_views/shared/IconTextButton';


class PackDecksSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingDeckCreate:         false,
      isProcessingDeckImport:         false,
      isStudyMixTypePulldownOpen:     false,
      newDeckFormIsInvalid:           false,
      studyMixType:                   this.getStudyMixType(),
    };

    /*
      this.props:
        addClasses,
        currentDeckId,
        currentUser,
        deckClickAction,
        decksPageIndex,
        decksPageSize,
        authenticityToken,
        initialWelcomeBubbleState,
        isFtse,
        isFtue,
        isMobileViewportSize,
        isProcessingDeckPagination,
        isProcessingLearnerPagination,
        onDeckDetailRequest,
        onPackCheckboxClick,
        onPaginateDecksRequest,
        pack,
        packSelectedState,
        selectedDecks,
        shouldPulseAddCardsButton,
        shouldPulseCreateDeckButton,
    */

    this.events = new EventManager();

    this._isMounted = false;
    this.roundCheckbox = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.manageCurrentDeck();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pack.deckIds != prevProps.pack.deckIds) {
      this.manageCurrentDeck();
    }

    if (this.props.pack.packId != prevProps.pack.packId) {
      this.manageStudyMixType();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.pack.decks || this.props.isLoadingPackDecks) {
      return (
        <LoadingOverlay
          addClasses="section-loading-overlay"
          isOpen={true}
          message="Loading Class Decks..."
          shouldTransitionIn={true}
          shouldTransitionOut={true}
        />
      );
    }

    const pack = this.props.pack;
    const isFtseClass = this.props.isFtse ? 'is-ftse' : '';
    const isFtueClass = this.props.isFtue ? 'is-ftue' : '';
    const classes = toClassStr(['pack-decks-section foo', isFtueClass, isFtseClass, this.props.addClasses]);

    return (
      <section className={classes} id="primary-nav" data-pack-id={this.props.pack.packId}>

        <div className="dashboard-pack-deck-list-header">
          {this.renderSelectAllCheckbox()}
          {this.renderStudyMixControls()}
          {this.renderPackActions()}
        </div>

        {this.renderDeckList()}
      </section>
    );
  }

  renderSelectAllCheckbox() {
    if (this.props.pack.decks?.length < 1) {
      return null;
    }

    const packCheckboxState = this.getPackCheckboxState();

    return (
      <div className="checkbox-and-label">
        <RoundCheckbox
          addClasses="pack-selected-checkbox"
          isChecked={packCheckboxState}
          isDisabled={this.props.pack.stats.deckCount < 1}
          isThreeState={true}
          onClick={this.handlePackCheckboxClick}
          ref={(elem) => {this.roundCheckbox = elem}}
          state={packCheckboxState}
          tooltipContent={this.renderSelectAllCheckboxTooltip()}
          tooltipPosition="right"
          value={this.props.pack.packId}
        />

        <div className="checkbox-label">All</div>
      </div>
    );
  }

  renderSelectAllCheckboxTooltip() {
    return (
      <RichContentTooltip
        addClasses="select-all-checkbox-tooltip" 
        body="These decks will be included in your next Smart Study session, when you click the big blue Study button above."
        delay={500}
        hasDismissButton={true}
        heading="Include Decks in Study"
      />
    );
  }

  renderStudyMixControls() {
    const pack = this.props.pack;

    if (pack.stats?.deckCount < 1) {
      return null;
    }

    if (pack.stats?.cardCount < 1) {
      return null;
    }

    const options = [
      {id: 'progressive', label: 'Progressive'}, 
      {id: 'random', label: 'Random'},
    ];

    return (
      <div className="study-mix-controls">
        <LabeledToggleButton 
          addClasses='study-mix-type-toggle-button'
          onOptionSelected={this.handleStudyMixOptionSelected}
          options={options}
          selectedOptionId={this.state.studyMixType}
        />
        {this.renderInfoButton()}
      </div>
    );
  }

  renderStudyMixTypesDynamicTooltipIconBody() {
    return (
      <>
        <p className='body-text'><b>Progressive:&nbsp;</b>Selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks will still repeat occasionally.</p>
        <p className='body-text'><b>Random:&nbsp;</b>Will select from a pool of cards across all selected decks. The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</p>
      </>
    );
  }

  renderDeckList() {
    const pack = this.props.pack;

    if (!pack.decks?.length > 0 && !this.props.isFtue) {
      return (
        <Fragment>
          {this.renderNoDecksInfo()}
          {this.renderEmptyDeckList()}
        </Fragment>
      )
    }

    const isSortable = (this.props.pack.permission == 'admin');

    return (
      <DeckList
        currentUser={this.props.currentUser}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isSortable={isSortable}
        onDeckDetailRequest={this.props.onDeckDetailRequest}
        pack={this.props.pack}
        selectedDecks={this.props.selectedDecks}
        shouldPulseAddCardsButton={this.props.shouldPulseAddCardsButton}
        shouldPulseCreateDeckButton={this.props.shouldPulseCreateDeckButton}
      />
    );
  }

  renderEmptyDeckList() {
    let emptyDecksArr = [];
    for (let i=0; i < 5; i++) {
      emptyDecksArr.push(this.renderEmptyDeckRow(i));
    }

    return (
      <ul className={"deck-list"}>
        {emptyDecksArr}
      </ul>
    );
  }

  renderEmptyDeckRow(key) {
    return (
      <li 
        className={"deck-row empty-deck-row"} 
        key={key}
        >
        <div className="deck-row-contents">
        <h4 className="deck-name">Lesson {key + 1}</h4>
        {this.renderStudyCardsButton()}
        </div>
    </li>
    )
  }

  renderInfoButton() {
    return (
      <DynamicTooltipIcon 
        addClasses="study-mix-type-dialog-button"
        delay={500}
        hasDismissButton={true}
        heading="About Study Mix Types"
        body={this.renderStudyMixTypesDynamicTooltipIconBody()}
        iconType="info"
        isMobileViewportSize={this.props.isMobileViewportSize}
        position="right"
      />
    )
  }

  renderMobilePackActions() {
    return (
      <div className="pack-actions">

        <CircledAddButton
          addClasses="pack-action create-deck"
          isProcessing={this.state.isProcessingDeckCreate}
          onClick={this.handleAddDeckButtonClick}
          title="Add a Deck to this Class"
        />

      </div>
    );
  }

  renderNoDecksInfo() {
    const pulseClass = this.props.shouldPulseCreateDeckButton ? 'pulse' : '';
    const classes = toClassStr(['pack-no-deck-info', pulseClass]);

    return (
      <div className={classes} onClick={this.handleAddDeckButtonClick}>
        <div className='pack-no-deck-info-name-and-caption'>
          <h2 className="deck-name">Create New Deck</h2>
          <p className="deck-name-caption">E.g., "Lesson 1", "Chapter 1"</p>
        </div>
        <CircledAddButton 
          isProcessing={this.state.isProcessingDeckCreate}
          onClick={this.handleAddDeckButtonClick}
          // shouldAllowClickPropagation={true}
        />
      </div>
    );
  }

  renderPackActions() {
    if (!this.props.pack.flags.areDecksEditable) {
      return null;
    }

    if (this.props.isMobileViewportSize) {
      return this.renderMobilePackActions();
    }

    return (
      <div className="pack-actions">

        {this.renderImportButton()}

        <CircledAddIconTextButton 
          addClasses="pack-action create-deck"
          label="Create New Deck"
          onClick={this.handleAddDeckButtonClick}
          tooltipContent="Create New Deck in this Class"
          tooltipPosition="top"
        />

        {this.renderMakeFlashcardsButton()}
      </div>
    );
  }

  renderImportButton() {
    const newFeatureClass = this.props.currentUser.flags?.features?.include_make_flashcards_button ? '' : 'new-feature';
    const importButtonClasses = toClassStr(['pack-action import-paste-flashcards', newFeatureClass,]);

    return (
      <SimpleTextButton 
        addClasses={importButtonClasses}
        customIcon={this.renderImportPasteFlashcardsIcon()}
        isProcessing={this.state.isProcessingDeckImport}
        label="Import/Paste Flashcards"
        onClick={this.handleImportPasteFlashcardsButtonClick}
        tooltipContent="Provide Q&A list, Vocab list, etc."
        tooltipPosition="top"
        uiTestId="import-cards-button"
      />
    );
  }

  renderImportPasteFlashcardsIcon() {
    return (
      <SvgIcon
        svgIconTag="custom-import-list-icon"
        color='tertiary'
        hoverColor='primary'
        shouldInheritColor={true}
      />
    )
  }

  renderMakeFlashcardsButton() {
    const currentUser = this.props.currentUser;
    const isInMakeFlashcardsButtonFeature = (currentUser.flags?.features?.ai_phase_2 && currentUser.flags?.features?.include_make_flashcards_button);

    if (!isInMakeFlashcardsButtonFeature) {
      return null;
    }

    return (
      <SimpleTextButton 
        addClasses="make-flashcards beta-feature"
        customIcon={this.renderMakeFlashcardsButtonIcon()}
        label="Make Flashcards"
        onClick={this.handleMakeFlashcardsButtonClick}
        tooltipContent="Make Flashcards by typing or with AI"
        tooltipPosition="left"
      />
    );
  }

  renderMakeFlashcardsButtonIcon() {
    return (
      <MakeFlashcardsButton />
    );
  }

  renderStudyMixTypesModalBody() {
    return (
      <div className="modal-content">
        <p className="modal-message text-align-left">
          <strong>'Progressive'</strong> selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks will still repeat occasionally.
        </p>
        <p className="modal-message text-align-left">
          <strong>'Random'</strong> will select from a pool of cards across all selected decks. The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.
        </p>
      </div>
    );
  }

  renderStudyCardsButton() {
    return (
      <StudyButton
        addClasses="study-button"
        isDisabled={true}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddDeckButtonClick = () => {
    this.triggerCreateDeckModalOpen();
  }

  handleImportPasteFlashcardsButtonClick = () => {
    this.triggerImportPasteFlashcardsModalOpen();
  }

  handleMakeFlashcardsButtonClick = () => {
    this.triggerMakeFlashcardsModalOpen();
  }

  handleMixTypeInfoButtonTouch = () => {
    this.triggerMixTypeInfoModalOpen();
  }

  handlePackCheckboxClick = (e) => {
    if (e) {
      e.stopPropagation;
    }

    const packCheckboxState = this.getPackCheckboxState();
    let isSelected;

    switch (packCheckboxState) {
      case 'all':
        isSelected = false;
      break;
      case 'none':
        isSelected = true;
      break;
      case 'mixed':
        isSelected = false;
      break;
    }

    const checkableDeckIds = this.getCheckableDeckIds(isSelected);
    
    packDeckTransform.update('selections', {
      userId: this.props.currentUser.userId,
      packId: this.props.pack.packId,
      deckIds: checkableDeckIds,
      isSelected: isSelected,
    });
  }

  handleStudyMixOptionSelected = (optionId) => {
    this.setStudyMixType(optionId);
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCreateDeckModalOpen = () => {
    EventManager.emitEvent('create-deck-modal:open', {
      currentUser: this.props.currentUser,
      packId: this.props.pack.packId,
    })
  }

  triggerImportPasteFlashcardsModalOpen = () => {   
    EventManager.emitEvent('make-flashcards-modal:open', {
      panel: 'importPasteFlashcards',
    });
  }

  triggerMakeFlashcardsModalOpen = () => { 
    EventManager.emitEvent('make-flashcards-modal:open', {
      deckId: this.props.currentDeckId || null,
      isDirectToCards: true,
      packId: this.props.pack.packId,
    });
  }

  triggerMixTypeInfoModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      title: 'About Study Mix Types',
      message: this.renderStudyMixTypesModalBody(),
    })
  }
  

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  canEdit = () => {
    const permission = this.props.pack.permission;
    return (['edit', 'admin'].indexOf(permission) != -1);
  }

  getPackCheckboxState = () => {
    const currentPack = this.props.pack;

    let deckCount = currentPack?.stats?.deckCount;
    let selectCount = currentPack?.localDeckTransforms.selections?.ids?.length;

    if (selectCount == 0) {
      return 'none';
    }

    if (selectCount == deckCount) {
      return 'all';
    }

    return 'mixed';
  }

  getCheckableDeckIds = (newState) => {
    if (!newState) {
      return this.props.pack.deckIds;
    }

    const checkableDeckIds = [];

    this.props.pack.decks.forEach(deck => {
      if (!deck.flags.isLocked && deck.stats.cardCount > 0) {
        checkableDeckIds.push(deck.deckId);
      }
    });

    return checkableDeckIds;
  }

  getStudyMixType = () => {
    const pack = this.props.pack;
    const userId = this.props.currentUser.userId;

    const studyMixType = userLocalStore.getPackStudyMixType(userId, pack);

    return studyMixType;
  };

  manageCurrentDeck = () => {
    if (this.props.pack.decks && this.props.currentDeckId) {
      this.scrollToCurrentDeck();
    }
  }

  manageStudyMixType = () => {
    this.setState({
      studyMixType: this.getStudyMixType(),
    })
  }

  scrollToCurrentDeck = () => {
    const deckRowId = `#deck-${this.props.currentDeckId}`;
    const currentDeckRow = document.querySelector(deckRowId);

    if (currentDeckRow) {
      scrollIntoView(currentDeckRow, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  setStudyMixType = (mixType) => {
    const packId = this.props.pack.packId;
    const userId = this.props.currentUser.userId;

    const newStudyMixType = mixType == 'random' ? 'random' : 'progressive';
    userLocalStore.setPackStudyMixType(userId, packId, newStudyMixType);

    this.setState({
      studyMixType: newStudyMixType,
    })
  };
}

export default PackDecksSection;
